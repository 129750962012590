
<!-- =========================================================================================
    File Name: .vue
    Description:
========================================================================================== -->

<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <vx-card class="p-2">
          <div class="text">
            <h4>Pricing details</h4>
            <p class="text-grey">&nbsp;</p>
          </div> <!-- mx-auto == center-->


          <!-- vs-avatar class=" my-6 block" size="80px" :src="card_1.userImg" />
           <p class="text-xl">{{ card_2.footer_text_left_value }}</p -->
          <div class="flex justify-between flex-wrap">
            <span>
               <p class="text-grey">{{ card_2.footer_text_right_label }}</p>
              <p class="text-xl">{{ card_2.footer_text_right_value }}</p>
            </span>
            <span>
              <p>         <vs-button
            type="gradient"
            class="w-full mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >{{ card_1.btn_text }}</vs-button></p>
            </span>
          </div>

          <vs-divider></vs-divider>

          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-grey">Total</p>
              <p class="text-xl">{{ card_2.footer_text_left_value }}</p>
            </span>
            <span>

            </span>
          </div>
          <vs-divider></vs-divider>
          <!-- div class="flex justify-between text-center">
            <span>
              <p class="text-xl font-semibold">{{ card_1.user_meta_1_title }}</p>
              <small class="text-grey">Consultation</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_1.user_meta_2_title }}</p>
              <small class="text-grey">Imaging</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_1.user_meta_3_title }}</p>
              <small class="text-grey">Laboratory</small>
            </span>
          </div >
          <vs-divider></vs-divider-->
          <div class="text">
            <h4>Instructions</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R1</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R2</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R3</vs-button>&nbsp;

&nbsp;
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Centers</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >A</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >B</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >C</vs-button>
&nbsp;

        </vx-card>
      </div>
    </div>
<h1>Care Team</h1>&nbsp;
<div class="vx-row">
      <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text-center">
            <h4>{{ card_1.name }}</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-2 block" size="80px" :src="card_1.userImg" />

          <vs-button
            type="gradient"
            class="w-full mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >{{ card_1.btn_text }}</vs-button>
        </vx-card>
      </div>

            <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text-center">
            <h4>{{ card_1.name }}</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-2 block" size="80px" :src="card_1.userImg" />

          <vs-button
            type="gradient"
            class="w-full mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >{{ card_1.btn_text }}</vs-button>
        </vx-card>
      </div>

        <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text-center">
            <h4>{{ card_1.name }}</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-2 block" size="80px" :src="card_1.userImg" />

          <vs-button
            type="gradient"
            class="w-full mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >{{ card_1.btn_text }}</vs-button>
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <vx-card class="p-2">
          <div class="text-left">
            <h4>{{ card_1.name }}</h4>
            <p class="text-grey">{{ card_1.profession }}</p>
          </div>
          <div class="text-right">
            <vs-button class="mt-4" type="border" color="#7367F0">Update</vs-button>
        </div>

        </vx-card>
      </div>
      </div>

    <!-- CARD WITH TABS COMPONENT -->
    <div class="vx-row match-height">
      <!-- SIMPLE CARD WITH TABS -->
      <div class="vx-col w-full md:w-1/1 mb-base">
        <vx-card>

        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { videoPlayer } from "vue-video-player";
import VxTimeline from "@/components/timeline/VxTimeline.vue";
import "video.js/dist/video-js.css";

export default {
  components: {
    VuePerfectScrollbar,
    videoPlayer,
    VxTimeline
  },
  data() {
    return {
      // card 1
      card_1: {},
      card_2: {},
      chatLog: [],
      chatMsgInput: "",

      card_9: {},

      timelineData: [
        {
          color: "primary",
          icon: "PlusIcon",
          title: "New Task Added",
          desc: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
          time: "25 Days Ago"
        },
        {
          color: "warning",
          icon: "AlertCircleIcon",
          title: "Task Update Found",
          desc: "Cupcake gummi bears soufflé caramels candy",
          time: "15 Days Ago"
        },
        {
          color: "success",
          icon: "CheckIcon",
          title: "Task Finished",
          desc: "Candy ice cream cake. Halvah gummi bears",
          time: "20 mins ago"
        }
      ],

      name: "",
      email: "",
      msg: "",

      playerOptions: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    }
  },
  mounted() {
    const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS;
    scroll_el.scrollTop = this.$refs.chatLog.scrollHeight;
  },
  created() {
    const HMinderEndPoint = "http://localhost:8090/";

    var config = {
      //headers: {'Access-Control-Allow-Origin': '*'}
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.get(HMinderEndPoint + "packg/getAll", config).then(response => {
      console.log("packgs");
      console.log(response.data);
      this.healthpackages = response.data.packgs;

     // console.log("ServiceType");
      //const Property = "type";
      //const playersByTeam = _.groupBy(response.data.packgs[0].serviceRequests, Property);
      //this.serviceTypes = playersByTeam;

      //console.log(this.serviceTypes);
    });
    // Card 1
    this.$http
      .get("/api/users/pixinvent/product/1")

      .then(response => {
        this.card_1 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 2
    this.$http
      .get("/api/users/pixinvent/product/sales/1")
      .then(response => {
        this.card_2 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 3
    this.$http
      .get("/api/users/pixinvent/product/goals/1")
      .then(response => {
        this.card_3 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 4
    this.$http
      .get("/api/users/89")
      .then(response => {
        this.card_4 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 5
    this.$http
      .get("/api/users/58")
      .then(response => {
        this.card_1 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 6
    this.$http
      .get("/api/users/16")
      .then(response => {
        this.card_6 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 7
    this.$http
      .get("/api/place/18")
      .then(response => {
        this.card_7 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 8
    this.$http
      .get("/api/place/76/weather")
      .then(response => {
        this.card_8 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Chat Log
    this.$http
      .get("/api/chat/demo-1/log")
      .then(response => {
        this.chatLog = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 9
    this.$http
      .get("/api/item/12/review")
      .then(response => {
        this.card_9 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Player Options
    this.$http
      .get("/api/card/card-basic/player-option")
      .then(response => {
        this.playerOptions = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss">
#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
</style>



